import Page from "@components/layout/page";
import ItemDetails from "@components/pages/info/item_details";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import ColorContrastChecker from "color-contrast-checker";
import { useRouter } from "next/router";
import React from "react";
import { Breadcrumb } from "react-bootstrap";
import ReactGA_4 from "react-ga4";

export default function Item(item) {
    const router = useRouter();
    React.useEffect(() => {
        ReactGA_4.send({ hitType: "pageview", page: router.asPath });
        if (!item || Object.entries(item).length === 0 || item.deleted) {
            router.replace("/404");
        }
    }, []);

    if (!item || Object.entries(item).length === 0 || item.deleted) {
        return <Page showsSearchBar title="Upplýsingar fundust ekki" />;
    }

    var ccc = new ColorContrastChecker();
    var color1 = item.background ? item.background : "#ffffff";
    var color2 = "#000000";
    var infoSectionTitleTextColor = "#000000";
    if (!ccc.isLevelAA(color1, color2, 14)) {
        infoSectionTitleTextColor = "#ffffff";
    }
    const t = GetTranslatedStrings();

    let metaImage = item.head_link;

    if (!metaImage) {
        metaImage = item.logo_link;
    }

    let headUrl = "https://1819.is/info/" + item.id + "/";

    if (item.sub_page) {
        headUrl = "https://1819.is/" + item.sub_page + "/";
    }

    return (
        <Page
            fluid
            headTitle={item.name}
            headImageUrl={metaImage}
            headDescription={item.description}
            headUrl={headUrl}
            showsSearchBar
            backgroundColor={item.background}
            showsFooter={false}
        >
            <Breadcrumb className="mx-auto col-xl-6 col-lg-9 ">
                <Breadcrumb.Item style={{ color: infoSectionTitleTextColor }} href="/">
                    {t.front_page}
                </Breadcrumb.Item>
                {router.query.q && (
                    <Breadcrumb.Item
                        style={{ color: infoSectionTitleTextColor }}
                        href={`/?q=${router.query.q}`}
                    >
                        {t.search_results}
                    </Breadcrumb.Item>
                )}

                <Breadcrumb.Item style={{ color: infoSectionTitleTextColor }} active>
                    {item.name}
                </Breadcrumb.Item>
            </Breadcrumb>
            <ItemDetails
                item={item}
                className="col-xl-6 col-lg-9 mx-auto"
                infoSectionTitleTextColor={infoSectionTitleTextColor}
            />
        </Page>
    );
}

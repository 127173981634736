import Bugsnag from "@bugsnag/js";
import { fetcherPublic1819 } from "@utils/fetcherPublic1819";
import RegistrationPage from "@components/RegistrationPage/RegistrationPage";
// Company is fetched on the server
export const getServerSideProps = async (ctx) => {
    var uri =
        "1819/details?slug=" + ctx.query.companySubID + "&omit-branches=true&query=" + ctx.query.q;
    try {
        const company = await fetcherPublic1819(uri);

        if (!company) {
            return { props: {} };
        }
        fetch(`https://old.1819.is/api/company_click/${company.id}/${ctx.query.q}`).catch(
            Bugsnag.notify
        );
        try {
            var regex = /(<([^>]+)>)/gi;
            company.description = company?.information?.replace(regex, "") || null;
        } catch (e) {
            console.log(e);
        }
        return { props: company };
    } catch (error) {
        console.log(error);
        return { props: {} };
    }
};

export default RegistrationPage;
